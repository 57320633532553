import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';
import { getOrganizationType, IOrganizationType } from './OrganizationType';

export interface IOrganizationUnitExtraProperties {
    TypeId: number;
    ImportId: string;
}

export const defaultOrganizationUnitExtraProperties: IOrganizationUnitExtraProperties = {
    TypeId: 0, ImportId: ''
};

export interface IOrganizationUnit extends IBaseAuditedEntity {
    id: string;
    parentId?: string;
    code: string;
    displayName: string;
    typeId: number;
    type: string;
    importId: string;
    extraProperties: IOrganizationUnitExtraProperties;
    childCount: number;
    canAddChild: boolean;
}

export const defaultOrganizationUnit: IOrganizationUnit = {
    id: '', parentId: '', code: '', displayName: '', typeId: -1,
    extraProperties: defaultOrganizationUnitExtraProperties, type: '', childCount: 0, canAddChild: false,
    importId: ''
};

export const getOrganizationUnits = (i: IBaseList<IOrganizationUnit>): IOrganizationUnit[] => {
    const d: IOrganizationUnit[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultOrganizationUnit,
            ...v,
            parentId: v.parentId || '',
            typeId: v.extraProperties && v.extraProperties.TypeId ? v.extraProperties.TypeId : 0,
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

export const getOrganizationUnitList = (i: IBaseList<IOrganizationUnit>, types: IOrganizationType[]): IOrganizationUnit[] => {
    const d: IOrganizationUnit[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultOrganizationUnit,
            ...v,
            parentId: v.parentId || '',
            typeId: v.extraProperties && v.extraProperties.TypeId ? v.extraProperties.TypeId : 0,
            importId: v.extraProperties.ImportId,
            type: getOrganizationType(types, v.extraProperties.TypeId).name,
            canAddChild: getOrganizationType(types, v.extraProperties.TypeId).childCount > 0
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

export const getOrganizationUnitForSave = (i: IOrganizationUnit): IOrganizationUnit => {
    if (i.parentId === '') {
        i.parentId = undefined;
    }
    i.extraProperties.TypeId = i.typeId;
    i.extraProperties.ImportId = i.importId;
    return i;
};

