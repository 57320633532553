import { IDossier, IDossierCreation, IDossierFilterDetailItems } from "../entities/MicroStrategyDossier";
import { IMSFilter } from "../entities/MicroStrategyFilters";
import { IMicroStrategyDossierChapterList } from "../entities/MicrostrategyDossierChapter";
import { IMicroStrategyDossierFilterMultiple, IMicroStrategyDossierFilterSelection, IMicroStrategyDossierFilterSingle, defaultMicroStrategyDossierFilterMultiple } from "../entities/MicrostrategyDossierFilter";
import { ReportNoFilterTextValue } from "../entities/ReportFilter";

declare global { var getXHRRequestPromise: any; }
declare global { var exportDossierJS: any; }
declare global { var publicLogin: any; }
declare global { var getAuthToken: any; }

export const exportDossier = async (
    baseServerUrl: string,
    libraryName: string,
    projectId: string,
    documentId: string,
    instanceId: string,
    filename: string,
    type: string
) => {
    exportDossierJS(localStorage.getItem('msPath'), localStorage.getItem('msauthurl'), projectId, documentId, instanceId, type, filename);
};

export const validateToken = async (validToken: (b: boolean) => void) => {
    try {
        getAuthToken(localStorage.getItem('msauthurl')).then(function (token: any) {
            if (!!token) {
                validToken(true);
            }
            else {
                validToken(false);
            }
        }).catch((error: any) => validToken(false));
    }
    catch (e: any) {
        validToken(false);
        console.log(e);
    }
}

export const createDossier = async (setDossier: (p: any) => void, item: IDossierCreation) => {
    try {
        //If we have auth token, show dossier.
        item.config.placeholder = document.getElementById(item.divId);
        item.config.url = item.url;
        item.config.navigationBar.enabled = item.debug || item.settings.showNavigationBar;
        item.config.filterFeature.enabled = item.debug || !item.settings.hideFilterSummary;
        item.config.filterFeature.summary = item.debug || !item.settings.hideFilterSummary;
        item.config.filterFeature.edit = item.debug || !item.settings.showFilters;
        item.config.errorHandler = item.errorHandler;
        item.config.sessionErrorHandler = item.errorHandler;
        item.config.disableCustomErrorHandlerOnCreate = true;
        window.microstrategy.dossier.create(item.config).then(async (dossier: any) => {
            dossier.filters = await dossier.getFilterList();
            dossier.instanceId = await dossier.getDossierInstanceId();

            dossier.addCustomErrorHandler((error: any) => {
                item.errorHandler && item.errorHandler(error);
                // Do something to handle the error
            });
            item.filtersUpdated && dossier.registerEventHandler(
                microstrategy.dossier.EventType.ON_FILTER_UPDATED,
                item.filtersUpdated
            );
            item.dossierChanged && dossier.registerEventHandler(
                microstrategy.dossier.EventType.ON_DOSSIER_INSTANCE_ID_CHANGE,
                item.dossierChanged
            );
            item.pageRendered && dossier.registerEventHandler(
                microstrategy.dossier.EventType.ON_PAGE_RENDER_FINISHED,
                item.pageRendered
            );
            item.pageSwitched && dossier.registerEventHandler(
                microstrategy.dossier.EventType.ON_PAGE_LOADED,
                item.pageSwitched
            );
            item.onError && dossier.registerEventHandler(
                microstrategy.dossier.EventType.ON_ERROR,
                item.onError
            );
            setDossier(dossier);
        }).catch((error: any) => item.errorHandler && item.errorHandler(error));
    }
    catch (e: any) {
        item.errorHandler && item.errorHandler(e);
        console.log(e);
    }
};

export const createPublicDossier = async (setDossier: (p: any) => void, item: IDossierCreation) => {
    item.config.placeholder = document.getElementById(item.divId);
    item.config.url = item.url;
    item.config.navigationBar.enabled = item.debug || item.settings.showNavigationBar;
    item.config.filterFeature.enabled = item.debug || !item.settings.hideFilterSummary;
    item.config.filterFeature.summary = item.debug || !item.settings.hideFilterSummary;
    item.config.filterFeature.edit = item.debug || !item.settings.showFilters;
    item.config.getLoginToken = publicLogin;
    item.config.customAuthenticationType = microstrategy.dossier.CustomAuthenticationType.AUTH_TOKEN;
    item.config.enableCustomAuthentication = true;

    try {
        window.microstrategy.dossier.create(item.config).then(async (dossier: any) => {
            dossier.filters = await dossier.getFilterList();
            dossier.instanceId = await dossier.getDossierInstanceId();
            item.filtersUpdated && dossier.registerEventHandler(
                microstrategy.dossier.EventType.ON_FILTER_UPDATED,
                item.filtersUpdated
            );
            item.dossierChanged && dossier.registerEventHandler(
                microstrategy.dossier.EventType.ON_DOSSIER_INSTANCE_ID_CHANGE,
                item.dossierChanged
            );
            item.pageRendered && dossier.registerEventHandler(
                microstrategy.dossier.EventType.ON_PAGE_RENDER_FINISHED,
                item.pageRendered
            );
            item.pageSwitched && dossier.registerEventHandler(
                microstrategy.dossier.EventType.ON_PAGE_SWITCHED,
                item.pageRendered
            );
            item.onError && dossier.registerEventHandler(
                microstrategy.dossier.EventType.ON_ERROR,
                item.onError
            );
            setDossier(dossier);
        });
    }
    catch (e: any) {
        item.onError && item.onError(e);
        console.log(e);
    }
};

export const applyDossierFilters = (used: IMSFilter[], dossier: any) => {
    if (dossier) {
        for (const f of used) {
            try {
                if (f.value === '' || f.value === ReportNoFilterTextValue) {
                    const k: IDossierFilterDetailItems = f.filterDetail.items[0];
                    if (f.showSelectAll && f.overideValue) {
                        if (f.filterDetail.supportMultiple) {
                            const v: IMicroStrategyDossierFilterMultiple = { filterInfo: { key: f.key, }, holdSubmit: true, selections: [] };
                            dossier.filterSearchMultiAttributes(v);
                        }
                        else {
                            const v: IMicroStrategyDossierFilterSingle = { filterInfo: { key: f.key, }, holdSubmit: true, selection: { name: k.name, value: k.value } };
                            dossier.filterSelectSingleAttribute(v);
                        }
                    }
                    else if (f.defaultSelection !== undefined && f.defaultSelection !== '' && f.value !== ReportNoFilterTextValue) {
                        const v: IMicroStrategyDossierFilterSingle = { filterInfo: { key: f.key, }, holdSubmit: true, selection: { name: k.name, value: f.defaultSelection || '' } };
                        dossier.filterSelectSingleAttribute(v);
                    }
                    else {
                        if (f.dossier) {
                            const v: IMicroStrategyDossierFilterSingle = { filterInfo: { key: f.key, }, holdSubmit: true, selection: { name: k.name, value: f.values[0].id } };
                            dossier.filterSelectSingleAttribute(v);
                        }
                        else {
                            dossier.filterDeselectAllAttributes({ filterInfo: { "key": f.key }, holdSubmit: true });
                        }
                    }
                }
                else {
                    if (f.filterType === 'attributeSlider') {
                        const inc: number = f.rangeIncrements || 2;
                        console.log(inc);
                        const k: IDossierFilterDetailItems[] = f.filterDetail.items;
                        const end: number = k.findIndex(q => q.value === f.value);
                        const start: number = end - inc;

                        dossier.filterAttributeMultiSlider({
                            filterInfo: { key: f.key }, selections: [start < 0 ? 0 : start, end < 0 ? k.length : end]
                        }
                        );
                    }
                    else {
                        if (f.supportMultiple) {
                            const v: IMicroStrategyDossierFilterMultiple = { ...defaultMicroStrategyDossierFilterMultiple, filterInfo: { key: f.key, }, holdSubmit: true };
                            const selections: IMicroStrategyDossierFilterSelection[] = [];
                            const values: string = f.value;
                            // selections: [{ name: f.displayValue, value: f.dossier ? f.value : f.msValue }]
                            for (const i of values.split(/,/)) {
                                selections.push({ value: i });
                            }
                            v.selections = selections;
                            dossier.filterSearchMultiAttributes(v);
                        }
                        else {
                            if (f.values.filter(q => q.id === f.value).length === 0) {
                                f.value = f.values[0].id;
                            }
                            const v: IMicroStrategyDossierFilterSingle = { filterInfo: { key: f.key, }, holdSubmit: true, selection: { name: f.displayValue, value: f.value } };
                            dossier.filterSelectSingleAttribute(v);
                        }
                    }
                }
            }
            catch (e) {
                console.log(`ERROR====>> applyDossierFilters : ${e}`);
            }
        }
        try {
            dossier.filterApplyAll();
        }
        catch (e) {
            console.log(`ERROR====>> applyDossierFilters : ${e}`);
        }
    }
}

export const getDossierFilters = async (setFilters: (p: any) => void, dossier: IDossier) => {
    try {
        const filters = await dossier.getFilterList();
        setFilters(filters);
    }
    catch { }
};

export const getDossierChapterList = async (setChapterList: (p: IMicroStrategyDossierChapterList) => void, dossier: any) => {
    //If we have auth token, show dossier.
    try {
        const chapters = await dossier.getChapterList();
        const chapterList: IMicroStrategyDossierChapterList = { chapters, totalPages: 0 };
        for (const c of chapterList.chapters) {
            chapterList.totalPages = chapterList.totalPages + c.children.length;
        }
        setChapterList(chapterList);
    }
    catch { }
};

export const setDossierSelectedPage = async (dossier: any, chapterIndex: number, pageIndex: number) => {
    //If we have auth token, show dossier.
    try {
        const chapters = await dossier.getChapterList();
        chapters[chapterIndex].getPageList(pageIndex)[pageIndex].setIsActive();
    }
    catch { }
};


// Rey Rey Chang  to  Everyone 1: 30 PM
// https://demo.microstrategy.com/MicroStrategyLibrary/api-docs/index.html#/Dossiers%20and%20Documents/getDossierDatasetFilterElements
// https://demo.microstrategy.com/MicroStrategyLibrary/api-docs/index.html#/Dossiers%20and%20Documents/setFilters
// https://microstrategy.github.io/embedding-sdk-docs/add-functionality/error-handling/