import React from 'react';
import { ISiteMenu } from '../entities/SiteMenu';
import '../styles/NavMenu.css';

export const saveRootMenu = (i: ISiteMenu) => {
    localStorage.setItem('nimbleSelectedMenu', i.rootId.toString());
}

const SiteFooter: React.FunctionComponent = () => {

    return (
        <>
            <div className="site-footer" />
            <div>
                <img className="footer-logo-usvi" alt="USVI" src="/images/logo/logo-vi.png" />
                <img className="footer-logo-otis" alt="USVI" src="/images/logo/logo-otis.png" />
            </div>
        </>
    );

};

export default SiteFooter;
