import React from 'react';
import { OECol } from '../core/components/grid/OECol';
import { OERow } from '../core/components/grid/OERow';
import { navigateToPage } from '../core/utilities/URL';

const Dashboard: React.FunctionComponent = () => {

    const onClickEarlyChildhood = () => {
        navigateToPage('/bi-embedded-one?pid=7ce3d0df-6b16-347a-db06-3a1276704798');
    };

    const onClickK12PublicSchools = () => {
        navigateToPage('/bi-embedded-one?pid=6d9f49b4-9e64-9870-98cf-3a12769ba4ab');
    };

    return (
        <div className="container home-page" >
            <OERow>
                <OECol md={8} xs={12}>
                    <div className="home-left-panel">
                        <img className="img-responsive" src="/images/branding/TrunkBay.jpg" alt="Trunk Bay" />
                    </div>
                </OECol>
                <OECol md={4} xs={12}>
                    <div className="home-right-panel">
                        <h2 className="home-page-title">Welcome to VIVIS</h2>
                        <p style={{ marginTop: '10px' }} >
                            It is my pleasure to introduce you to VIVIS!
                            The Department has been
                            working with other agencies since August 2013 to implement a Statewide Longitudinal Data
                            System, called the Virgin Islands Virtual Information System (VIVIS).
                        </p>
                        <p>
                            VIVIS consolidates
                            and links data that currently resides within many disparate data systems across Departments
                            into one quality controlled, secure data repository.
                        </p>
                    </div>
                </OECol>
            </OERow>
            <div className="cleardiv" />
            <OERow>
                <OECol md={3} xs={12}>
                    <div onClick={onClickEarlyChildhood} className="home-footer-panel">
                        <h2 className="home-page-title">Early Childhood</h2>
                        <img src="/images/branding/EarlyChildhood.jpg" alt="Early Childhood" />
                        <h2 className="home-footer-link">{`< Click to Explore >`}</h2>
                    </div>
                </OECol>
                <OECol md={3} xs={12}>
                    <div onClick={onClickK12PublicSchools} className="home-footer-panel">
                        <h2 className="home-page-title">K-12 Public Schools</h2>
                        <img src="/images/branding/K12PublicSchools.jpg" alt="Public Schools" />
                        <h2 className="home-footer-link">{`< Click to Explore >`}</h2>
                    </div>
                </OECol>
                <OECol md={3} xs={12}>
                    <div className="home-footer-panel">
                        <h2 className="home-page-title">Higher Education</h2>
                        <img src="/images/branding/HigherEducation.jpg" alt="Higher Education" />
                        <h2 className="home-footer-link">{`< Coming Soon >`}</h2>
                    </div>
                </OECol>
                <OECol md={3} xs={12}>
                    <div className="home-footer-panel">
                        <h2 className="home-page-title">Workforce Development</h2>
                        <img src="/images/branding/WorkforceDevelopment.jpg" alt="Workforce Development" />
                        <h2 className="home-footer-link">{`< Coming Soon >`}</h2>
                    </div>
                </OECol>
            </OERow>
        </div>
    );
};

export default Dashboard;