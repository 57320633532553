import React from 'react';
import OEHeading from '../core/components/general/OEHeading';

const Maintenance: React.FunctionComponent = () => {
    return (
        <div className="container m-t-40">
            <OEHeading size={1} text="Nimble Portal - Offline" />
            <p>
                USVI STARS Reporting and Analytics Portal is currently down for maintenance. We expect to be back up shortly . Please check back soon.
            </p>
            <p>We appreciate your understanding and patience during this time.</p>
        </div>
    );
};

export default Maintenance;