export enum ReportProcessingStep {
    ApplyFilters = 'AF',
    ApplyFiltersAfterUpdate = 'AFPS',
    CheckToken = 'CT',
    DossierFiltersLoaded = 'DFL',
    DossierLoaded = 'DL',
    Finished = 'F',
    FilterReport = 'FR',
    GroupLoaded = 'GL',
    LoadDossier = 'LD',
    LoadDossierFilterInitial = 'LDI',
    LoadDossierFilters = 'LDF',
    LoadGroup = 'LG',
    LoadReport = 'LR',
    LoadPage = 'LP',
    LoadMenu = 'LM',
    LoadSection = 'LS',
    NotDefined = 'ND',
    PageLoaded = 'PL',
    ReloadDossierFilters = 'RDF',
    ReportLoaded = 'RL',
    SectionLoaded = 'SL',
    UpdateFilters = 'UF',
    TokenValid = 'TV',
    ValidatingToken = 'VT',
    UpdateDossierFilters = 'UDF',
    UpdateFilterValue = 'UFV',
}

export const ReportProcessingStepLabel = new Map<string, string>([
    [ReportProcessingStep.ApplyFilters, 'Apply Filters'],
    [ReportProcessingStep.ApplyFiltersAfterUpdate, 'Apply Filters After Page Switch'],
    [ReportProcessingStep.CheckToken, 'Check Token'],
    [ReportProcessingStep.DossierFiltersLoaded, 'Dossier Filters Loaded'],
    [ReportProcessingStep.DossierLoaded, 'Dossier Loaded'],
    [ReportProcessingStep.Finished, 'Finished'],
    [ReportProcessingStep.FilterReport, 'Filter Report'],
    [ReportProcessingStep.GroupLoaded, 'Group Loaded'],
    [ReportProcessingStep.LoadDossier, 'Load Dossier'],
    [ReportProcessingStep.LoadDossierFilters, 'Load Dossier Filters'],
    [ReportProcessingStep.LoadGroup, 'Load Group'],
    [ReportProcessingStep.LoadReport, 'Load Report'],
    [ReportProcessingStep.LoadPage, 'Load Page'],
    [ReportProcessingStep.LoadMenu, 'Load Menu'],
    [ReportProcessingStep.LoadSection, 'Load Section'],
    [ReportProcessingStep.NotDefined, 'Not Defined'],
    [ReportProcessingStep.PageLoaded, 'Page Loaded'],
    [ReportProcessingStep.ReloadDossierFilters, 'Reload Dossier Filters'],
    [ReportProcessingStep.ReportLoaded, 'Report Loaded'],
    [ReportProcessingStep.SectionLoaded, 'Section Loaded'],
    [ReportProcessingStep.UpdateFilters, 'Update Filters'],
    [ReportProcessingStep.TokenValid, 'Token Valid'],
    [ReportProcessingStep.ValidatingToken, 'Validating Token'],
    [ReportProcessingStep.UpdateDossierFilters, 'Update Dossier Filters'],
    [ReportProcessingStep.UpdateFilterValue, 'Update Filter Values'],
]);


export const updateProcessingStep = (i: ReportProcessingStep, setStep: any) => {
    window.setTimeout(() => {
        setStep(i);
    }, 5);
}

export const reportProcessingStep = (i: ReportProcessingStep, setNotification: any, debug: boolean) => {
    debug && setNotification({ message: ReportProcessingStepLabel.get(i), type: 'info' });
}

