import { padZeroStart } from './String';

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getShortTime = (hour: number, min: number) => {
    if (hour > 11) {
        return `${hour > 12 ? padZeroStart(hour - 12, 2) : padZeroStart(hour, 2)}:${padZeroStart(min, 2)} PM`;
    }
    return `${padZeroStart(hour === 0 ? 12 : hour, 2)}:${padZeroStart(min, 2)} AM`;
};

export const getFutureDate = (days: number): Date => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    date.setHours(0, 0, 0, 0);
    return date;
};


export const formatDateTime = (d: string, f?: string): string => {
    try {

        if (d !== null) {
            let date: Date = new Date(d);

            //let date: Date = new Date(`${d}`);
            // console.log('date2',date2);
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            // console.log('date3', date3);
            const format: string = f || 'mm/dd/yyyy';
            const minDate: Date = new Date('01/01/1900');
            if (date > minDate) {
                return format
                    .replace(/yyyy/g, padZeroStart(date.getFullYear(), 4))
                    .replace(/yy/g, date.getFullYear().toString().substring(2, 4))
                    .replace(/dd/g, padZeroStart(date.getDate(), 2))
                    .replace(/mm/g, padZeroStart(date.getMonth() + 1, 2))
                    .replace(/hh/g, padZeroStart(date.getHours(), 2))
                    .replace(/ii/g, padZeroStart(date.getMinutes(), 2))
                    .replace(/ss/g, padZeroStart(date.getSeconds(), 2))
                    .replace(/st/g, getShortTime(date.getHours(), date.getMinutes()))
                    .replace(/Month/g, monthNames[date.getMonth()])
                    .replace(/Mon/g, shortMonthNames[date.getMonth()]);
            }
        }
    }
    catch {
        return '-';
    }
    return '';
};

export const emptyDate = (d: Date) => {
    return formatDateTime(d.toLocaleString(), 'mm/dd/yyyy') === '01/01/1900';
};

export const getEmptyDate = (): Date => {
    return new Date(1900, 0, 1, 0, 0, 0);
};


export const changeDateFormat = (d: string): string => {  // expects Y-m-d
    var splitDate = d.split('-');
    if (splitDate.length === 0) {
        return "";
    }

    var year = splitDate[0];
    var month = splitDate[1];
    var day = splitDate[2];

    return month + '-' + day + '-' + year;
}